import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./header";

function Routes(props) {
  const context = props.context;
  return (
    <Router>
      <Header context={context} channels={props.channels}></Header>
    </Router>
  );
}

export default Routes;
