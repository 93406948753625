import React, { useEffect, useState } from "react";
import { Typography, Button, Tooltip } from "@mui/material";
import { pageTitleContainer, glassButtonStyle } from "../styles/general";

const breakpoint = 1400;
export default function PageTitle({context}) {
    const [title, setTitle] = useState(<Typography variant="body1">{context.tenant_name}</Typography>);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        window.addEventListener("pageTitleChange", (e) => {
            setTitle(e.detail);
        });
    }, []);

    return (
        <>
            {
                window.innerWidth > breakpoint ? (
                    <div style={pageTitleContainer()}>
                        {title}
                    </div>
                ) : (
                    <>
                        <Tooltip
                            arrow
                            placement="bottom-end"
                            open={open}
                            title={title}
                        >
                            <Button
                                fullWidth
                                variant="contained"
                                sx={glassButtonStyle(context.theme)}
                                onClick={() => {setOpen(!open)}}
                            >
                                Options
                            </Button>
                        </Tooltip>
                    </>
                )
            }
        </>
    )
}