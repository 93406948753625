import React, { useRef, useState, useEffect } from "react";
import { ReactSketchCanvas } from 'react-sketch-canvas';

import { Button, CircularProgress, Typography } from "@mui/material";
import CreateIcon from '@mui/icons-material/Create';
import LayersClearIcon from '@mui/icons-material/LayersClear';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';

const verticalStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "1vw"
}

export default function SigCanvas({saveSignature, setFileType, setFileSize}) {

    const [drawing, setDrawing] = useState(true);
    const canvasRef = useRef(null);
    const [loadingCanvas, setLoadingCanvas] = useState(true);
    const [penSize, setPenSize] = useState(2);

    const enterDrawingMode = () => {
        setDrawing(true);
        canvasRef.current.eraseMode(false);
    }

    const exitDrawingMode = () => {
        setDrawing(false);
        canvasRef.current.eraseMode(true);
    }

    const resetCanvas = () => {
        canvasRef.current.resetCanvas()
    }

    const changePenSize = async (size) => {
        setLoadingCanvas(true);
        let paths = await canvasRef.current.exportPaths();
        await setPenSize(size);
        await canvasRef.current.loadPaths(paths);
        setLoadingCanvas(false);
    }

    const saveSig = async () => {
        setLoadingCanvas(true);
        let img = await canvasRef.current.exportImage("png");
        await saveSignature(img);
        setLoadingCanvas(false);
    }

    useEffect(() => {
        setLoadingCanvas( !(canvasRef !== null && canvasRef !== undefined) );
        setFileType("image/png");
        setFileSize(0);
    }, [canvasRef])

    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            gap: "1vw",
            marginTop: "2vh"
        }}>
            <ReactSketchCanvas
                ref={canvasRef}
                style={{
                    border: "2px solid rgba(0, 0, 0, 0.4)",
                    borderRadius: "5px",
                }}
                width="50vw"
                height="20vh"
                strokeWidth={penSize}
                strokeColor="black"
            />
            {
                loadingCanvas ? (
                    <div style={verticalStyle}>
                        <CircularProgress/>
                        <Typography>Loading Drawing Pane...</Typography>
                    </div>
                ) : (
                    <div style={verticalStyle}>
                        <Button
                            variant="contained"
                            onClick={() => {resetCanvas()}}
                            startIcon={<ClearIcon/>}
                            color="secondary"
                            fullWidth
                        >
                            Clear
                        </Button>
                        <Button
                            onClick={drawing ? () => {exitDrawingMode()} : () => {enterDrawingMode()}}
                            variant="contained"
                            startIcon={drawing ? <LayersClearIcon/> : <CreateIcon/>}
                            fullWidth
                        >
                            {drawing ? "Eraser" : "Draw"}
                        </Button>
                        <div style={{ display: "flex", justifyContent: "space-evenly", gap: "5px" }}>
                            <Button variant="contained" color="primary" onClick={() => {changePenSize(penSize - 1)}} >-</Button>
                            <Typography style={{ textAlign: "center" }}>Pen Size: {penSize}</Typography>
                            <Button variant="contained" color="primary" onClick={() => {changePenSize(penSize + 1)}}>+</Button>
                        </div>
                        <Button
                            onClick={() => {saveSig()}}
                            variant="contained"
                            color="primary"
                            startIcon={<SaveIcon/>}
                            fullWidth
                        >
                            Save Signature
                        </Button>
                    </div>
                )
            }
        </div>
    )
}