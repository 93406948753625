import React, { useState, useEffect } from "react";
import { AccountCircle } from "@mui/icons-material";
import { IconButton, Tooltip, Typography } from "@mui/material";
import AccountPopover from "./accountPopover";

export default function AccountButton({context}) {
    const [ anchor, setAnchor ] = useState(false);


    useEffect(() => {

    }, []);

    return (
        <>
            <IconButton
                style={{ color: context.theme.top_menu.font }}
                onClick={(e) => {setAnchor(e.currentTarget)}}
            >
                <Tooltip title={<Typography variant="body1">Account</Typography>} arrow placement="bottom">
                    <AccountCircle/>
                </Tooltip>
            </IconButton>
            <AccountPopover
                context={context}
                anchor={anchor}
                setAnchor={setAnchor}
            />
        </>
    )
}