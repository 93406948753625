import React, { useState, useEffect } from "react";
import { Button, CircularProgress, Tooltip, Typography } from "@mui/material";
import { glassButtonStyle, twoBlockBox, centerAlignSubBoxStyle } from "../../styles/general";
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import TimerIcon from '@mui/icons-material/Timer';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import { AttendanceApi } from "@unity/components";

export default function AttendSection({context}) {
    const [settings, setSettings] = useState(false);
    const [flex, setFlex] = useState(false);
    const [clock, setClock] = useState(null);
    const [loading, setLoading] = useState(false);
 
    const getSettings = async () => {
        try {
            const res = await AttendanceApi.getSettings();
            if(!res.data) throw "Call Failed";
            setSettings(res.data);
        } catch (e) {
            console.error("Failed to Load Settings", e);
            alert("Failed to load settings. Please refresh and try again");
        }
    }

    const getFlexBalance = async () => {
        try {
            const res = await AttendanceApi.getFlexBalance();
            if(!res.data) throw "Call Failed";
            setFlex(res.data);
        } catch (e) {
            console.error("Failed to Load Flex balance", e);
            alert("Failed to load flexi balance. Please refresh and try again");
        }
    }

    const getClock = async () => {
        try {
            const res = await AttendanceApi.getClockStatus();
            if(!res.status || res.status !== 200) throw "Call Failed";
            setClock(res.data);
        } catch (e) {
            console.error("Failed to Load Clock", e);
            alert("Failed to load clock data. Please refresh and try again");
        }
    }

    const loadData = async () => {
        setLoading(true);
        setClock(null);

        await getSettings();
        await getFlexBalance();
        await getClock();

        setLoading(false);
    }

    useEffect(() => {
        loadData();
        window.addEventListener("clockEventAttend", (event) => {loadData()})
    }, []);

    const handleChange = async () => {
        setLoading(true);
        navigator.geolocation.getCurrentPosition(updateClock, () => {
            alert("Failed to Clock in/out. It appears that you have rejected location permissions. MYBE Time and Attendance requires this location data to clock you in and out as per company policy");
            setloading(false);
        });
    }

    const updateClock = async (pos) => {
        const data = {
            status: !clock ? 1 : 0,
            longitude: pos.coords.longitude,
            latitude: pos.coords.latitude,
            agent_id: context.agent_id
        };

        try {
            const res = await AttendanceApi.setClock(data);
            if(res.success === undefined || !res.success || res.statusCode == 500) throw "Call Failed";
            window.dispatchEvent(new CustomEvent('clockEventHandler', {
                bubbles: true,
                cancelable: true,
                detail: { status: data.status}
            }));

            const flexRes = await AttendanceApi.getFlexBalance();
            if(!flexRes.data) throw "Failed to Update Flex Balance";
            setFlex(flexRes.data);
            setClock(!clock);
        } catch (e) {
            console.error("Failed to update clock", e);
            alert("Failed to update clock. Please refresh and try again");
        }
        setLoading(false);
    }

    const clockOutButton = () => (
        <Tooltip title={<Typography variant="body1">Clock Out of Time and Attendance</Typography>} placement="left">
            <Button
                sx={glassButtonStyle(context.theme)}
                variant="contained"
                onClick={() => {handleChange()}}
                fullWidth
                startIcon={<TimerOffIcon/>}
            >
                Clock Out
            </Button>
        </Tooltip>
    )

    const clockInButton = () => (
        <Tooltip title={<Typography variant="body1">Clock In to Time and Attendance</Typography>} placement="left">
            <Button
                sx={glassButtonStyle(context.theme)}
                variant="contained"
                onClick={() => {handleChange()}}
                fullWidth
                startIcon={<TimerIcon/>}
            >
                Clock In
            </Button>
        </Tooltip>
    )
    
    if(loading) return (
        <div style={{flex: 1}}>
            <Button
                sx={glassButtonStyle(context.theme)}
                variant="contained"
                fullWidth
                startIcon={<CircularProgress style={{ height: "20px", width: "20px", color: context.theme.top_menu.font }} />}
            >
                Loading...
            </Button>
        </div>
    );

    if(clock === null) return (
        <Tooltip title={<Typography variant="body1">Clock Not Found</Typography>}>
            <RunningWithErrorsIcon style={{ color: context.theme.top_menu.font }}/>
        </Tooltip>
    )

    return (
        <div style={{flex: 1}}>
            {
                clock ? clockOutButton() : clockInButton()
            }
            {
                settings.flexi_time === 1 && (
                    <Tooltip title="Flexi Time Remaining">
                        <HistoryToggleOffIcon/> <Typography variant="body1">{flex}</Typography>
                    </Tooltip>
                )
            }
        </div>
    )
}