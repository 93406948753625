import React from "react";
import { IconButton, Popover, Typography } from "@mui/material";
import { popoverStyle, popoverContainerStyle, popoverHeaderStyle, popoverHeaderTitleContainerStyle, popoverBodyStyle } from "../../styles/general";
import { Close, Notifications } from "@mui/icons-material";
import NotificationSection from "./notificationSection";

const transform = {
    vertical: "top",
    horizontal: "right"
  };

const anchorOrigin = {
    vertical: "bottom",
    horizontal: "left"
  }

export default function NotificationPopover({context, anchor, setAnchor, channels, notifications, fetchNotifications}) {
    const open = Boolean(anchor);
    const id = open ? "notification-popover" : undefined;

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchor}
            onClose={() => {setAnchor(null)}}
            transformOrigin={transform}
            anchorOrigin={anchorOrigin}
            sx={popoverStyle()}
        >
            <div style={popoverContainerStyle(context.theme)}>
                <div style={popoverHeaderStyle(context.theme)}>
                <div style={popoverHeaderTitleContainerStyle()}>
                        <Notifications style={{ color: context.theme.top_menu.font }}/>
                        <Typography variant="h5" >Notifications</Typography>
                    </div>
                    <IconButton
                        style={{ color: context.theme.top_menu.font, marginRight: "1vw" }}
                        onClick={() => {setAnchor(null)}}
                    >
                        <Close/>
                    </IconButton>
                </div>
                <div style={popoverBodyStyle(context.theme)}>
                    <NotificationSection 
                        context={context} 
                        channels={channels}
                        notifications={notifications}
                        fetchNotifications={fetchNotifications}
                    />
                </div>
            </div>
        </Popover>
    )
}