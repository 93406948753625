import React, { useState } from "react";
import ImageIcon from '@mui/icons-material/Image';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Typography, Snackbar } from "@mui/material";

import SigCanvas from "./sigCanvas";
import SigCropper from "./sigCropper";

import { PortalApi, DocumentVaultAPI } from "@unity/components";

export default function SigModal({context, sig, setSig}) {
    const [type, setType] = useState(null);
    const [fileType, setFileType] = useState(false);
    const [fileSize, setFileSize] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [imgURL, setImgURL] = useState(false);

    const saveSignature = async (img = false) => {
        if(!img) return ("Please Set a Signature First");
        setLoading(true);
        try {
            const res = await PortalApi.setSignature(context.agent_id, {
                base64: img,
                name: `${context.name} signature`,
                size: fileSize,
                type: fileType
            });
            if(res?.data == undefined || res?.data == null) throw "Call Failed";
            setSig(false);
            window.location.reload();
        } catch (error) {
            alert(`Failed to save signature - ${error}`);
            setError(error);
        }
        setLoading(false);
    }

    const getSig = async (sigId) => {
        setLoading(true);
        try {
            const res = await DocumentVaultAPI.generateTempLink(sigId);
            if(res?.data === null || res?.data === undefined) throw "Call Failed";
            setImgURL(res.data);
        } catch (error) {
            alert(`Failed to Load Signature - ${error}`);
            setError(error);
        }
        setLoading(false);
    }

    if(loading) return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "3vh"
        }}>
            <CircularProgress/>
            <Typography>Loading...</Typography>
        </div>
    )

    if(error) return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "3vh"
        }}>
            <CancelIcon color="secondary" size="32"/>
            <Typography>{typeof error == "string" ? error : "ERROR 001"}</Typography>
        </div>
    )

    return (
        <>
            <Dialog
                open={sig}
                fullWidth={true}
                maxWidth={"md"}
                onClose={() => {setSig(false)}}
            >
                <DialogTitle>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center"  }}>
                        <Typography variant="h5">Edit Your Signature</Typography>
                        <IconButton onClick={() => {setSig(false)}}><CloseIcon/></IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    {
                        type == null ? (
                            <div style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                gap: "5vw"
                            }}>
                                <Button
                                    onClick={() => {setType(0)}}
                                    variant="contained"
                                    color="primary"
                                    startIcon={<EditIcon/>}
                                >
                                    Draw a new signature
                                </Button>
                                <Button
                                    onClick={() => {setType(1)}}
                                    variant="contained"
                                    color="primary"
                                    startIcon={<ImageIcon/>}
                                >
                                    Upload a signature image
                                </Button>
                            </div>
                        ) : (
                            <div style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                gap: "5vw"
                            }}>
                                <Button
                                    onClick={() => {setType(null)}}
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<CancelIcon/>}
                                >
                                    Cancel
                                </Button>
                            </div>
                        )
                    }
                    {
                        context.signature_id !== null && (
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "5vw",
                                marginTop: "5vh"
                            }}>
                                <Button
                                    onClick={() => {getSig(context.signature_id)}}
                                    variant="contained"
                                    color="primary"
                                    startIcon={<VisibilityIcon/>}
                                >
                                    Preview Signature
                                </Button>
                            </div>
                        )
                    }
                    {
                        imgURL && (
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "5vw",
                                marginTop: "5vh"
                            }}>
                                <img
                                    width="440"
                                    height="230"
                                    style={{ border: "2px solid rgba(0,0,0, 0.2)", borderRadius: "3px" }}
                                    src={imgURL}
                                />
                            </div>
                        )
                    }
                    {
                        type == 0 && (
                            <SigCanvas
                                saveSignature={saveSignature}
                                setFileType={setFileType}
                                setFileSize={setFileSize}
                            />
                        )
                    }
                    {
                        type == 1 && (
                            <SigCropper
                                saveSignature={saveSignature}
                                setFileType={setFileType}
                                setFileSize={setFileSize}
                            />
                        )
                    }
                </DialogContent>
            </Dialog>
        </>
    )
}