import React, { useState, useEffect, Suspense } from "react";
import { TenantApi, CostingsApi, StorageManager, PersonnelApi, PortalApi } from "@unity/components";
import { Select, Button, Dialog, DialogContent, DialogTitle, IconButton, CircularProgress, Typography, FormControl, InputLabel, Fade, LinearProgress } from "@mui/material";
import { glassButtonStyle, dialogTitleStyle, dialogHeaderStyle, dialogBodyStyle, dialogBodyContainerStyle, selectStyle, twoBlockBox } from "../../styles/general";
import WorkIcon from '@mui/icons-material/Work';
import WorkOffIcon from '@mui/icons-material/WorkOff';
import CloseIcon from "@mui/icons-material/Close";
import TimekeeperCreateDrawer from "./timekeeperCreateDrawer";

const TimekeeperSummary = React.lazy(() => import("./timekeeperSummary"));

const CATEGORYNAME = "attend";
const BANDAPPROVETYPE = "timekeeper";
const LOCALSTORAGEWORKNAME = "currentWork";
const TYPESTRING = "Labour";
export default function TimekeeperSection({context, setTimekeeper, timekeeper = false}) {
    const [moduleSettings, setModuleSettings] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);
    const [currentApp, setCurrentApp] = useState(false);
    const [jobs, setJobs] = useState(false);
    const [currentJob, setCurrentJob] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [createOpen, setCreateOpen] = useState(false); 

    const sm = new StorageManager();

    const fetchCurrentJob = async (tempJobs = jobs) => {
        try {
            const currentJobRes = sm.getObject(LOCALSTORAGEWORKNAME, null);
            if(currentJobRes == null) return setCurrentJob(null);
            const workingJob = tempJobs[currentJobRes.costing_id];
            if(!workingJob) return setCurrentJob(null); // Invalid Job / Deleted Job


            
            const diff = calculateDateDifference(new Date(currentJobRes.startTime), new Date());
            const hours = (diff / 60).toFixed(0);
            const minutes = (diff % 60).toFixed(0);

            
            workingJob.startTime = currentJobRes.startTime;
            workingJob.hours = `${hours}:${minutes}`;
            workingJob.type = currentJobRes.type;
            workingJob.gross = currentJobRes.gross;
            workingJob.rate = currentJobRes.rate;
            setCurrentJob(workingJob);
        } catch (e) {
            console.error("Failed to pull current job", e);
            alert("Failed to load current job details. Please refresh and try again");
        }
    }

    const fetchModuleSettings = async () => {
        try {
            const payload = {
                category_type: CATEGORYNAME,
                page: 1
            };

            const res = await TenantApi.moduleSettingsIndexForCategory(payload);
            if(!res.ok) throw "Call Failed";

            if(!Array.isArray(res.data.data) || res.data.data.length <= 0) return setTimekeeper(false);
            
            const settings = res.data.data[0];
            if(settings.settings == undefined || settings.settings == null) return setTimekeeper(false);
            if(settings.settings.band == undefined || settings.settings.band == null) return setTimekeeper(false);
            if(settings.settings.band !== BANDAPPROVETYPE) return setTimekeeper(false);

            setModuleSettings(settings);
            setTimekeeper(true);
        } catch (e) {
            console.error("Failed to load module settings", e);
            alert("Failed to load settings. Please refresh and try again");
        }
    }

    const fetchAvailableJobs = async (jobSearch = null) => {
        try {
            const payload = {
                costing_name: jobSearch !== "" ? jobSearch : null,
                perPage: 100
            }
            const res = await CostingsApi.timekeeperIndex(payload);
            if(!res.ok) throw "Call Failed";

            const jobMap = {};
            for(let i = 0; i < res.data.length; i++) {
                const costing = res.data[i];
                if(!costing) continue;
                jobMap[costing.costing_id] = costing;
            }
            setJobs(jobMap);
            return jobMap;
        } catch (e) {
            console.error("Failed to load available jobs", e);
            alert("Failed to load current jobs - please refresh and try again");
        }
    }

    const fetchCurrentApp = async () => {
        try {
            const res = await PortalApi.getApplication(context.app_uuid);
            if(!res.data && !res.data.application) throw "Call Failed";
            setCurrentApp(res.data.application);
        } catch (e) {
            console.error("Failed to load current app", e);
            alert("Failed to load current app - please refresh and try again");
        }
    }

    const calculateDateDifference = (start, end) => {
        const diff = end - start;
        return Math.floor(diff / 60000);
    }

    const loadData = async () => {
        setLoading({current: 0, max: 4});
        await fetchModuleSettings(); setLoading({current: 1, max: 4});
        const tempJobs = await fetchAvailableJobs(); setLoading({current: 2, max: 4});
        await fetchCurrentJob(tempJobs); setLoading({current: 3, max: 4});
        await fetchCurrentApp();
        setLoading(false);
    }

    useEffect(() => {
        loadData();
    }, []);

    const handleWorkStart = async () => {
        let agent = {};
        try {
            const res = await PersonnelApi.getPersonnelSingle(context.agent_id);
            if(!res.data) throw "Call Failed";
            agent = res.data;
        } catch (e) {
            console.error("Failed to fetch agent details", e);
            alert("Failed to fetch your user information");
        }

        const localPayload = {...jobs[selectedJob]};
        localPayload.startTime = new Date().toISOString();
        localPayload.type = prompt("Describe your current task:");
        localPayload.rate = !agent.cost_to_business ? 
            (localPayload.cost_to_business ? localPayload.cost_to_business : null) : agent.cost_to_business;
        localPayload.gross = !agent.cost_to_customer ?
            (localPayload.cost_to_customer ? localPayload.cost_to_customer : null) : agent.cost_to_customer;
        sm.setObject(LOCALSTORAGEWORKNAME, localPayload);
        setCurrentJob(localPayload);
    };

    const handleWorkEnd = async () => {
        setLoading({current: 0, max: 1});
        try {
            const start = new Date(currentJob.startTime);
            let time = new Date(calculateDateDifference(start, new Date()));
            const hours = (time / 60).toFixed(0);
            let minutes = (time % 60).toFixed(0);
            if(minutes < 10) minutes = `0${minutes}`;
            time = Number.parseFloat(`${hours}.${minutes}`);

            const payload = {
                'costing_id': currentJob.costing_id,
                'type': TYPESTRING,
                'what': currentJob.type,
                'who_id': context.agent_id,
                'who_name': context.name,
                'date': start.toJSON(),
                'qty': time,
                'rate': currentJob.rate,
                "gross": currentJob.gross
            }

            const res = await CostingsApi.timekeeperWorkedLinesStore(payload);
            if(!res.ok) throw "Call Failed";
            sm.removeItem("currentWork");
            setCurrentJob(null);

        } catch (e) {
            console.error("Failed to end work", e);
            alert("Failed to end work. Please refresh and try again");
        }
        setLoading(false);
    }

    if(loading) return (
        <div style={{ flex: 1 }}>
            <Button
                sx={[...glassButtonStyle(context.theme), {width: "100%"}]}
                variant="contained"
                startIcon={
                    <CircularProgress 
                        style={{ height: "20px", width: "20px", color: context.theme.top_menu.font }}
                        variant="determinate"
                        value={(loading.current / loading.max) * 100}
                    />
                }
                fullWidth
            >
                Loading
            </Button>
        </div>
    );
    if(!timekeeper) return (<></>);
    if(!currentJob && currentJob !== null) return (<Typography variant="body1">Unable to Load Current Job Data.</Typography>);
    if(!currentApp) return (<Typography variant="body1">Unable to load app data</Typography>)

    const NewJobForm = () => (
        <div style={dialogBodyContainerStyle()}>
            <div
                style={{
                    backgroundColor: selectedJob ? "rgba(30,158,30,0.5)" : "rgba(183,109,0,0.5)",
                    width: "100%",
                    borderRadius: "5px"
                }}
            >
            <FormControl fullWidth>
                <InputLabel style={{ color: context.theme.top_menu.font }}>Job</InputLabel>
                <Select
                    native
                    defaultValue={selectedJob}
                    sx={selectStyle(context.theme)}
                    onChange={(e) => {setSelectedJob(e.target.value)}}
                >
                    <option style={{ color: "black" }} value={""}></option>
                    {Object.keys(jobs).map((j, key) => (
                        <option style={{ color: "black" }} key={key} value={j}>ABC {j} - {jobs[j].costing_name}</option>
                    ))}
                </Select>
            </FormControl>
            </div>
            {
                selectedJob !== null ? (
                    <div style={{ marginTop: "2vh", width: "100%" }}>
                        <Button
                            sx={{...glassButtonStyle(context.theme), backgroundColor: "orange", color: "black"}}
                            variant="contained"
                            onClick={() => {handleWorkStart()}}
                            startIcon={<WorkIcon/>}
                            fullWidth
                        >
                            Start Work
                        </Button>
                    </div>
                ) : (<></>)
            }
        </div>
    )

    const EndJob = () => (
        <div style={dialogBodyContainerStyle()}>
            <div style={{padding: "1vw"}}>
                <Typography variant="body1">Job Name: {currentJob.costing_name}</Typography>
                <Typography variant="body1">Work Title: {currentJob.type}</Typography>
                <Typography variant="body1">Start Time: {new Date(currentJob.startTime).toUTCString()}</Typography>
                <Typography variant="body1">Hours Worked: {currentJob.hours}</Typography>
            </div>
            <Button
                variant="contained"
                onClick={() => {handleWorkEnd()}}
                startIcon={<WorkOffIcon/>}
                sx={{...glassButtonStyle(context.theme), backgroundColor: "green", color: "white"}}
                fullWidth
            >
                End Work
            </Button>
        </div>
    )

    return (
        <div style={{flex: 1}}>
            <Button
                sx={[...glassButtonStyle(context.theme), {width: "100%"}]}
                variant="contained"
                onClick={() => {setOpen(true)}}
                startIcon={<WorkIcon/>}
                fullWidth
            >
                Timekeeper
            </Button>
            <Dialog
                open={open}
                fullWidth
                maxWidth={"md"}
                onClose={() => {setOpen(false)}}
                sx={dialogBodyStyle(context.theme)}
            >
                <DialogTitle style={dialogHeaderStyle(context.theme)}>
                    <div style={dialogTitleStyle(context.theme)}>
                        <Typography variant="h5"><WorkIcon/> Timekeeper {currentApp ? `- ${currentApp.name}` : ""}</Typography>
                        <IconButton onClick={() => {setOpen(false)}}>
                            <CloseIcon style={{ color: "white" }} />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div style={{ height: "auto" }}>
                        {
                            currentJob ? <EndJob/> : <NewJobForm/>
                        }
                    </div>
                    <div>
                        <TimekeeperCreateDrawer
                            context={context}
                            loading={loading}
                            setLoading={setLoading}
                            open={createOpen}
                            setOpen={setCreateOpen}
                            costing={selectedJob ? jobs[selectedJob] : false}
                            settings={moduleSettings.settings}
                        />
                        <Suspense fallback={<Fade in={true}><LinearProgress/></Fade>}>
                            <TimekeeperSummary context={context} moduleSettings={moduleSettings}/>
                        </Suspense>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}