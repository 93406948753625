import React from "react";
import { Button, Card, CardContent } from "@mui/material";
import "../styles/noPermissionStyles.css";

export default function NoPermission(props) {
    return (
        <div className="page">
            <Card variant="outlined" className="messageCard">
                <CardContent>
                    <h2>No Permissions</h2>
                    <p>You do not have permission to use this system. Please contact your manager or admin if you believe this is incorrect.</p>
                    <Button 
                    onClick={() => {window.history.go(-1)}}
                    variant="outlined">
                        Go Back
                    </Button>
                </CardContent>
            </Card>
        </div>
    )
}