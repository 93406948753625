import React from "react";
import { Button } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import { glassButtonStyle, twoBlockBox } from "../../styles/general";

export default function LogoutButton({context}) {

    return (
        <div style={{flex: 1}}>
            <Button
                startIcon={<LogoutIcon/>}
                sx={[...glassButtonStyle(context.theme), {width: "100%"}]}
                fullWidth
                variant="contained"
                onClick={() => {window.location.pathname = `/logout`}}
            >
                Logout
            </Button>
        </div>
    )
}