import React, { useEffect, useState } from "react";

import Routes from "./Routes";
import * as config from "./root-config";
import NoPermission from "./components/noPermission";

export default function Root(props) {
  const Pusher = require("pusher-js");
  const [ channels, setChannels ] = useState([]);
  const [ pusher, setPusher ] = useState(
    new Pusher(config.pusherClientId, {
      cluster: config.pusherRegion,
    })
  );

  const initChannels = async () => {
    const tempChannels = [];

    Promise.all([
      await pusher.subscribe(props.auth.tenant_uuid),
      await pusher.subscribe(props.auth.app_uuid),
      await pusher.subscribe(props.auth.id)
    ]).then((results) => {
      tempChannels["tenant"] = results[0];
      tempChannels["app"] = results[1];
      tempChannels["user"] = results[2];
      bindEvents(tempChannels);
      setChannels(tempChannels);
    })
    .catch((e) => {console.error("Failed to subscribe to pusher channels", e)})

    setChannels(tempChannels);
  }

  const bindEvents = async (chs = channels) => {
    if(chs['tenant']) chs['tenant'].bind('NotificationEvent', (e) => {
      window.dispatchEvent(new CustomEvent("NotificationReceived"));
    });

    if(chs['user']) chs['user'].bind('NotificationEvent', (e) => {
      window.dispatchEvent(new CustomEvent("NotificationReceived"));
    });

    if(chs['app']) chs['app'].bind('NotificationEvent', (e) => {
      window.dispatchEvent(new CustomEvent("NotificationReceived"));
    });
}

  useEffect(() => {
    initChannels();
  }, [])

  return props.auth.access !== null ?(
      <Routes context={props.auth} channels={channels} />
  ) : (
    <NoPermission context={props.auth} />
  );
}