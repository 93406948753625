import React, { useState } from "react";
import { Button, Tooltip, Typography } from "@mui/material";
import BrushIcon from '@mui/icons-material/Brush';
import { glassButtonStyle } from "../../styles/general";
import SigModal from "./sigModal";

export default function SignatureSection({context}) {
    const [open, setOpen] = useState(false);

    return (
        <div style={{flex: 1}}>
            <SigModal sig={open} setSig={setOpen} context={context} />
            <Tooltip title={<Typography variant="body1">Manage/Set or View the signature linked to your account</Typography>} placement="top">
                <Button
                    variant="contained"
                    fullWidth
                    startIcon={<BrushIcon/>}
                    onClick={() => {setOpen(true)}}
                    sx={glassButtonStyle(context.theme)}
                >
                    Signature
                </Button>
            </Tooltip>
        </div>
    )
}