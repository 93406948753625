import React, { useState, useEffect } from "react";
import { Button, CircularProgress, Typography, Tooltip, IconButton } from "@mui/material";
import { centerAlignSubBoxStyle, notificationContainer, notificationBlockStyle, twoBlockBox, glassButtonStyle, notificationBlockControls, rightAlignedContained } from "../../styles/general";
import { NotificationsApi } from "@unity/components";
import PersonIcon from '@mui/icons-material/Person';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import RemoveIcon from '@mui/icons-material/Remove';
import LinkIcon from '@mui/icons-material/Link';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import "./notificationSectionStyles.css";

export default function NotificationSection({context, channels, notifications, fetchNotifications}) {
    const [ loading, setLoading ] = useState(false);

    const handleDismiss = async (itemId) => {
        setLoading(true);
        try {
            const res = await NotificationsApi.markAsRead(itemId, false);
            if(!res.data) throw "Call Failed";

            await fetchNotifications();
        } catch (e) {
            console.error("Failed to dismiss notification", e);
            alert("Failed to dismiss notification. Please refresh and try again");
        }
        setLoading(false);
    }

    const handleDismissAll = async () => {
        setLoading(true);
        try {
            const ids = [];
            notifications.map((notif, key) => {
                ids.push(notif.id);
            })

            const res = await NotificationsApi.markManyAsRead(ids);
            if(!res.data) throw "Call Failed";

            await fetchNotifications();
        } catch (e) {
            console.error("Failed to dismiss notifications", e);
            alert("Failed to dismiss notifications. Please refresh and try again");
        }
        setLoading(false);
    }

    const loadData = async () => {
        setLoading(true);
        await fetchNotifications();
        setLoading(false);
    };

    useEffect(() => {
        loadData();
    }, []);

    if(loading) return (
        <div style={centerAlignSubBoxStyle()}>
            <CircularProgress style={{color: context.theme.top_menu.font}}/>
            <Typography variant="body1">Loading Notifications...</Typography>
        </div>
    )

    if(!Array.isArray(notifications) || notifications.length <= 0) return (
        <div style={centerAlignSubBoxStyle()}>
            <Typography variant="body1" style={{ color: context.theme.top_menu.font }}>No Notifications Discovered</Typography>
        </div>
    )

    const Notification = ({item}) => (
        <div style={notificationBlockStyle()}>
            <Typography variant="body1">{item.name}</Typography>
            {
                item.poster === "MYBE" ? (
                    <div dangerouslySetInnerHTML={{ __html: item.description  }} />
                ) : (
                    <Typography variant="body2">{item.description}</Typography>
                )
            }
            <div style={twoBlockBox()}>
                <div>
                    
                    <Typography variant="body2">
                        <AvTimerIcon style={{ color: context.theme.top_menu.font }}/>
                        {item.created_at}
                    </Typography>
                </div>
                <div>
                    
                    <Typography variant="body2">
                        <PersonIcon style={{ color: context.theme.top_menu.font }}/>
                        {item.poster}
                    </Typography>
                </div>
            </div>
            <div style={notificationBlockControls()}>
                <Tooltip title={<Typography variant="body1">Dismiss Notification</Typography>}>
                    <Button
                        sx={glassButtonStyle(context.theme)}
                        variant="contained"
                        onClick={() => {handleDismiss(item.id)}}
                        fullWidth
                        startIcon={<RemoveIcon/>}
                    >
                        Dismiss
                    </Button>
                </Tooltip>
                <span>
                <Tooltip title={<Typography variant="body1">{!item.link ? "Go to notification source" : "No Link Attached"}</Typography>}>
                        <Button
                            sx={glassButtonStyle(context.theme)}
                            variant="contained"
                            onClick={() => {window.location = `https://${item.link}`}}
                            disabled={item.link == undefined || item.link == null}
                            fullWidth
                            startIcon={<LinkIcon/>}
                        >
                            Link
                        </Button>
                </Tooltip>
                </span>
            </div>
        </div>
    )

    return (
        <>
        <div style={notificationContainer()} className="notifications-scroll-window">
            {
                notifications.map((item, key) => (
                    <Notification item={item} key={key}/>
                ))
            }
        </div>
        <div style={rightAlignedContained()}>
            <Tooltip title={<Typography variant="body1">Dismiss all notifications</Typography>}>
                <IconButton
                    onClick={() => {handleDismissAll()}}
                >
                    <MarkEmailReadIcon style={{ color: context.theme.top_menu.font }}/>
                </IconButton>
            </Tooltip>
        </div>
        </>
    )
}