import React, {useState, useRef, useEffect} from "react";
import AvatarEditor from "react-avatar-editor";
import { Slider, Button, CircularProgress, Typography, Input } from "@mui/material";
// import FileBase64 from "react-file-base64";
import Save from "@mui/icons-material/Save";

export default function SigCropper({saveSignature, setFileType, setFileSize}) {
    const [scale, setScale] = useState(1);
    const [image, setImage] = useState(null);
    const [baseImage, setBaseImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const canvasRef = useRef(null);

    const handleSliderChange = (event, newValue) => {
        if(newValue < 1) setScale(1);
        setScale(newValue);
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    })

    const handleImageUpload = async (file) => {
        setImage(await toBase64(file));
        setFileType(file.type);
        setFileSize(file.size);
        setBaseImage(file);
        // setImage(e.base64);
        // setFileType(e.type);
        // setFileSize(e.file.size);
        // setBaseImage(e);
    }

    const saveSig = async () => {
        setLoading(true);
        try {
            await saveSignature(canvasRef.current.getImage().toDataURL());
        } catch (error) {
            alert("Failed to Store New Signature");
        }
        setLoading(false);
    }

    useEffect(() => {
        if(canvasRef !== null) setLoading(false);
    }, [canvasRef]);

    if(loading) return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "5vh"
        }}>
            <CircularProgress/>
            <Typography>
                Loading File Uploader...
            </Typography>
        </div>
    )

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "5vh",
                marginTop: "5vh"
            }}
        >
            <AvatarEditor
                ref={canvasRef}
                image={image}
                borderRadius={5}
                width={600}
                height={200}
                scale={scale}
            />
            <div>
                <Input
                    id="sigUploader"
                    name="sigRaw"
                    type="file"
                    label="Upload Signature Image"
                    fullWidth
                    onChange={(e) => { handleImageUpload(e.target.files[0])}}
                />
                {/* <FileBase64 multiple={false} onDone={(e) => handleImageUpload(e)} /> */}
                <Button
                    onClick={() => {saveSig()}}
                    variant="contained"
                    disabled={!image}
                    startIcon={<Save/>}
                >
                    Save
                </Button>
            </div>
            <Typography>Zoom Level</Typography>
            <Slider
                value={scale}
                onChange={handleSliderChange}
                disabled={!image}
                step={0.1}
                min={1}
                max={6}
                valueLabelDisplay="on"
            />
        </div>
    )
}