import React, { useState, useEffect } from "react";
import { CellTower } from "@mui/icons-material";
import { IconButton, Tooltip, Typography } from "@mui/material";
import CommunicationPopover from "./communicationPopover";

export default function CommunicationButton({context}) {
    const [ anchor, setAnchor ] = useState(false);


    useEffect(() => {

    }, []);

    return (
        <>
            <IconButton
                style={{ color: context.theme.top_menu.font }}
                onClick={(e) => {setAnchor(e.currentTarget)}}
            >
                <Tooltip title={<Typography variant="body1">Communication</Typography>} arrow placement="bottom">
                    <CellTower/>
                </Tooltip>
            </IconButton>
            <CommunicationPopover
                context={context}
                anchor={anchor}
                setAnchor={setAnchor}
            />
        </>
    )
}