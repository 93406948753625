import React, { useState, useEffect } from "react";
import { PersonnelApi, NotificationsApi } from "@unity/components";
import { Button, CircularProgress, FormControl, Input, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { glassButtonStyle, centerAlignSubBoxStyle, selectStyle, textFieldStyle } from "../../styles/general";
import { Send } from "@mui/icons-material";

export default function BroadcastSection({context}) {
    const [ message, setMessage ] = useState("");
    const [ userUuid, setUserUuid ] = useState(0);
    const [ users, setUsers ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    
    const sendBroadcast = async () => {
        setLoading(true)
        const payload = {
            "name": `Message from ${context.name}`,
            "description": message,
            "tenant_uuid": context.line_manager && userUuid === -1 ? PropaneSharp.auth.tenant_uuid : null,
            "user_uuid": userUuid === -1 ? null : userUuid,
            "poster": context.name,
            "channel": userUuid === -1 && context.line_manager ? context.tenant_uuid : userUuid,
            "event": "notification",
            "message": "New Broadcast Message Recieved"
        };

        if( !context.line_manager && ( userUuid === null || userUuid === 0 ) )
            return alert ("You must be a line manager to send a group broadcast");
        
        try {
            const res = await NotificationsApi.saveNotification(payload);
            if(!res.data) throw "Call Failed";
            setUserUuid(0);
            setMessage("");
        } catch (e) {
            console.error("Failed to Send Broadcast", e);
            alert("Failed to Send Broadcast", e);
        }
        setLoading(false);
    }

    const getUsers = async () => {
        try {
            const res = await PersonnelApi.getPersonnel();
            if(!res.data) throw "Call Failed";
            setUsers(res.data);
        } catch (e) {
            console.error("Failed to load users", e);
            alert("Failed to load users. Please refresh and try agian");
        }
    }

    const loadData = async () => {
        setLoading(true);
        await getUsers();
        setLoading(false);
    }

    useEffect(() => {
        loadData()
    }, []);

    if(loading) return (
        <div style={centerAlignSubBoxStyle()} >
            <CircularProgress style={{ color: context.theme.top_menu.font }}/>
        </div>
    )

    if(!Array.isArray(users) || users.length <= 0) return (
        <div style={centerAlignSubBoxStyle()} >
            <Typography variant="body1">Nobody to Message</Typography>
        </div>
    )

    return (
        <>
        <div style={{ display: "flex", justifyContent: "space-between", gap: "1vw" }}>
                <FormControl fullWidth>
                    <InputLabel style={{ color: context.theme.top_menu.font }}>Recipient</InputLabel>
                    <Select
                        onChange={(e) => {setUserUuid(e.target.value)}}
                        name="user_uuid"
                        sx={selectStyle(context.theme)}
                        native={true}
                    >
                        {
                            context.line_manager && (
                                <option value={-1} style={{ color: "black" }}>
                                    Entire Department
                                </option>
                            )
                        }
                        {
                            users.map((option, key) => 
                                <option value={option.uuid} key={key} style={{ color: "black" }}>
                                    {option.name}
                                </option>
                            )
                        }
                    </Select>
                </FormControl>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", gap: "1vw" }}>
                <FormControl fullWidth>
                    <InputLabel style={{ color: context.theme.top_menu.font }}>Message</InputLabel>
                    <Input
                        type="text"
                        multiline
                        name="message"
                        onChange={(e) => {setMessage(e.target.value)}}
                        sx={textFieldStyle(context.theme)}
                    />
                </FormControl>

                <div style={centerAlignSubBoxStyle()}>
                    <Button
                        variant="contained"
                        sx={glassButtonStyle(context.theme)}
                        onClick={() => {sendBroadcast()}}
                        fullWidth
                        rows={3}
                        startIcon={<Send/>}
                    >
                        Send Message
                    </Button>
                </div>
        </div>
        </>
    )
}