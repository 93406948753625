import React, { useState } from "react";
import { CostingsApi } from "@unity/components";
import { Cancel, ExpandMore, Save } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Container, TextField, Typography, Box } from "@mui/material";
import { glassButtonStyle, accordionStyle, headerTextField } from "../../styles/general";

const DAYMILI = 86400000;
const DEFAULTTYPE = "Labour";
export default function TimekeeperCreateDrawer({context, settings, open, setOpen, loading, setLoading, costing}) {
    const [data, setData] = useState({});

    const validateDate = (date) => {
        try {
            if(!date) return false;
            const now = new Date();
            const d = new Date(Date.parse(date));
            if(!settings) return false; // User has not settings therefore cannot edit at all

            return settings?.extendEdit ||
                Math.abs(now.getTime() - d.getTime()) <= (DAYMILI * 7)
        } catch (e) {
            console.error("Failed to parse/validate date", e);
            alert("Date invalid, please enter a valid date");
            return false;
        }
    }

    const formatDate = (date) => {
        const d = new Date(date);
        let month = ((d.getMonth() + 1) < 10) ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
        let day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
        return `${d.getFullYear()}-${month}-${day} ${d.getUTCHours()}:${d.getMinutes()}`
    }

    const handleSave = async () => {
        if(!validateDate(data.date)) return;
        setLoading(true);
        try {
            let time = data.qty;
            let exploded = time.split(":");
            time = Number.parseFloat(`${exploded[0]}.${exploded[1]}`);
            const res = await CostingsApi.timekeeperWorkedLinesStore({
                costing_id: costing.costing_id,
                type: DEFAULTTYPE,
                who_id: context.agent_id,
                who_name: context.name,
                qty: time,
                rate: costing?.cost_to_business ? costing?.cost_to_business : 0,
                gross: costing?.charge_out_rate ? costing?.charge_out_rate : 0,
                // date: formatDate(Date.parse(data.date)),
                date: new Date(data.date).toJSON(),
                what: data.what
            });
            if(!res.ok || !res.data) throw "Call Failed";
            alert("Record Saved");
        } catch (e) {
            console.error("Failed to save work record", e);
            alert("Failed to save work record. Please refresh and try again");
        }
        setLoading(false);
    }

    const handleChange = (name, value) => {
        const temp = {...data};
        temp[name] = value;
        setData(temp);
    }

    return (
        <Container>
            <Accordion
                expanded={Boolean(open && !loading && costing)}
                onChange={(e) => {setOpen(!open)}}
                style={accordionStyle()}
            >
                <AccordionSummary
                    expandIcon={!costing ?
                        <Cancel sx={{ color: "#ff7e7e" }}/> :
                        loading ? <CircularProgress/> : <ExpandMore sx={{ color: "white" }} />
                    }
                    style={{cursor: costing ? "pointer" : "not-allowed"}}
                >
                    <Typography 
                        variant="body1"
                        style={{ padding: "0.5vw" }}
                    >
                        {costing ? "Create New Work Record" : "Create New Work Record (Please Select a Job First)"}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box
                        component="form"
                        sx={{'& .MuiTextField-root': { m: 1, width: '25ch' }}}
                    >
                        <TextField
                            label="Description"
                            InputLabelProps={{shrink: true}}
                            name="what"
                            onChange={(e) => {handleChange(e.target.name, e.target.value)}}
                            size="small"
                            sx={headerTextField(context)}
                            fullWidth
                        />
                        <TextField
                            label="When"
                            InputLabelProps={{shrink: true}}
                            name="date"
                            onChange={(e) => {handleChange(e.target.name, e.target.value)}}
                            size="small"
                            type="datetime-local"
                            sx={headerTextField(context)}
                            fullWidth
                        />
                        <TextField
                            label="Hours Worked"
                            InputLabelProps={{shrink: true}}
                            name="qty"
                            onChange={(e) => {handleChange(e.target.name, e.target.value)}}
                            size="small"
                            type="time"
                            sx={headerTextField(context)}
                            fullWidth
                        />
                        <Button
                            onClick={() => {handleSave()}}
                            variant="contained"
                            color="success"
                            fullWidth
                            startIcon={<Save/>}
                            sx={ {...glassButtonStyle(context.theme), color: "white"} }
                        >Save</Button>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Container>
    )
}